import React, { useContext, useEffect, useMemo, useState } from 'react';
import { BiLabel } from 'react-icons/bi';
import useAsyncEffect from 'use-async-effect';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';
import {
  SettingCardButton,
  SettingsCard,
  SettingsCardText,
  SettingsCardTitle,
} from '@/features/shop-settings/settings-card';
import { HSpace } from '@/components/spacing';
import { AccountContext } from '@/features/account-context';
import {
  useStoreSettingsApi,
  VariantMetafieldsResp as VariantMetafieldType,
} from '@/webapi/use-store-settings-api';
import { CardSlider } from '@/components/card-slider';
import { OptionsCard } from '@/components/options-card';
import { SearchInput } from '@/components/searchInput';

export function VariantMetafieldsCard() {
  const {
    account: {
      store: { alias },
    },
  } = useContext(AccountContext);

  const [searchText, setSearchText] = useState(``);
  const { getVariantMetafields, loading, setVariantMetafields } =
    useStoreSettingsApi();
  const [metafields, setMetafields] = useState<
    VariantMetafieldType['metafields']
  >([]);
  const [initialMetafields, setInitialMetafields] = useState<
    VariantMetafieldType['metafields']
  >([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [newMetafield, setNewMetafield] = useState<{
    namespace: string;
    keys: string[];
  } | null>(null);

  const onSearchTextChanged = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(ev.target.value);
  };

  const debouncedSearch = useDebouncedCallback(onSearchTextChanged, 300);

  useAsyncEffect(async () => {
    try {
      const result = await getVariantMetafields();
      if (result && Array.isArray(result.metafields)) {
        setMetafields(
          result.metafields.map((mf) => ({
            ...mf,
            keys: Array.isArray(mf.keys) ? mf.keys : [],
          })),
        );
        setInitialMetafields(
          result.metafields.map((mf) => ({
            ...mf,
            keys: Array.isArray(mf.keys) ? mf.keys : [],
          })),
        );
      } else {
        setMetafields([]);
        setInitialMetafields([]);
      }
    } catch (error) {
      setMetafields([]);
      setInitialMetafields([]);
    }
  }, [alias]);

  useEffect(() => {
    setHasUnsavedChanges(
      JSON.stringify(metafields) !== JSON.stringify(initialMetafields),
    );
  }, [metafields, initialMetafields]);

  const groupedMetafields = useMemo(
    () =>
      metafields.reduce((acc, { namespace, keys }) => {
        if (!acc[namespace]) acc[namespace] = [];
        acc[namespace].push(...(Array.isArray(keys) ? keys : []));
        return acc;
      }, {} as Record<string, string[]>),
    [metafields],
  );

  const filteredGroupedMetafields = useMemo(() => {
    if (!searchText.trim()) return groupedMetafields;
    const lowerSearch = searchText.toLowerCase();
    return Object.entries(groupedMetafields).reduce(
      (acc, [namespace, keys]) => {
        const namespaceMatches = namespace.toLowerCase().includes(lowerSearch);
        const filteredKeys = keys.filter((key) =>
          key.toLowerCase().includes(lowerSearch),
        );
        if (namespaceMatches) {
          acc[namespace] = keys;
        } else if (filteredKeys.length > 0) {
          acc[namespace] = filteredKeys;
        }
        return acc;
      },
      {} as Record<string, string[]>,
    );
  }, [groupedMetafields, searchText]);

  const onNewMetafieldKey = async (namespace: string, newKey: string) => {
    setMetafields((prev) =>
      prev.map((mf) =>
        mf.namespace === namespace ? { ...mf, keys: [...mf.keys, newKey] } : mf,
      ),
    );
  };

  const onRemoveMetafield = async (namespace: string, keyToRemove: string) => {
    setMetafields((prev) =>
      prev.map((mf) =>
        mf.namespace === namespace
          ? { ...mf, keys: mf.keys.filter((key) => key !== keyToRemove) }
          : mf,
      ),
    );
  };

  const onDeleteCard = async (namespace: string) => {
    setMetafields((prev) => prev.filter((mf) => mf.namespace !== namespace));
  };

  const handleAddNewMetafield = () => {
    if (!newMetafield) {
      setNewMetafield({ namespace: ``, keys: [] });
    }
  };

  const onEditNamespace = (value: string) => {
    if (newMetafield) {
      const trimmed = value.trim();
      if (trimmed !== ``) {
        setMetafields((prev) => {
          const existing = prev.find((mf) => mf.namespace === trimmed);
          if (existing) {
            return prev.map((mf) =>
              mf.namespace === trimmed
                ? {
                    ...mf,
                    keys: Array.from(
                      new Set([...mf.keys, ...newMetafield.keys]),
                    ),
                  }
                : mf,
            );
          }
          return [...prev, { namespace: trimmed, keys: newMetafield.keys }];
        });
        setNewMetafield(null);
      } else {
        setNewMetafield({ ...newMetafield, namespace: value });
      }
    }
  };

  const setNewMetafieldKeys = async (key: string) => {
    setNewMetafield({
      ...newMetafield,
      keys: newMetafield.keys.filter((k) => k !== key),
    });
  };

  const onEditNewKey = async (value: string) => {
    if (newMetafield) {
      setNewMetafield({ ...newMetafield, keys: [...newMetafield.keys, value] });
    }
  };

  const handleOnDelete = async () => {
    setNewMetafield(null);
  };

  const onSaveChange = async () => {
    if (!hasUnsavedChanges) return;
    const result = await setVariantMetafields(metafields);
    if (result && Array.isArray(result.metafields)) {
      setMetafields(result.metafields);
      setInitialMetafields(result.metafields);
    }
    setHasUnsavedChanges(false);
  };

  return (
    <SettingsCard loading={loading}>
      <UpperSection>
        <SettingsCardTitle>
          <BiLabel size={24} />
          <HSpace value={1.5} />
          Variant Metafields
        </SettingsCardTitle>
        {metafields?.length > 0 && (
          <SearchContainer>
            <SearchInput
              defaultValue={searchText}
              grow
              onChange={debouncedSearch}
            />
          </SearchContainer>
        )}
      </UpperSection>
      <SettingsCardText>
        Product variants with the following metafields namespaces and keys will
        be synced to all recommendations and upsells globally.
      </SettingsCardText>
      <CardSlider>
        {Object.entries(filteredGroupedMetafields).map(([namespace, keys]) => (
          <OptionsCard
            key={namespace}
            title={namespace}
            options={keys}
            onAddKey={(key) => onNewMetafieldKey(namespace, key)}
            onRemoveKey={(key) => onRemoveMetafield(namespace, key)}
            onDelete={() => onDeleteCard(namespace)}
            removeText="Are you sure you want to remove this metafield?"
            optionsCollapseAfter={4}
          />
        ))}
        {newMetafield ? (
          <OptionsCard
            title={newMetafield.namespace}
            options={newMetafield?.keys || []}
            onAddKey={onEditNewKey}
            onRemoveKey={(key) => setNewMetafieldKeys(key)}
            onDelete={handleOnDelete}
            isDraft
            removeText="Are you sure you want to remove this new metafield? Any unsaved changes will be lost."
            onEditTitle={onEditNamespace}
            optionsCollapseAfter={4}
          />
        ) : (
          <AddNewCard onClick={handleAddNewMetafield}>
            + Add New Metafield
          </AddNewCard>
        )}
      </CardSlider>
      {hasUnsavedChanges && (
        <ButtonWrapper>
          <SettingCardButton small black onClick={onSaveChange}>
            Save Changes
          </SettingCardButton>
        </ButtonWrapper>
      )}
    </SettingsCard>
  );
}

const UpperSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SearchContainer = styled.div`
  max-width: 200px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 1rem 1rem;
`;

const AddNewCard = styled.div`
  min-width: 250px;
  min-height: 150px;
  border: 2px dashed #555;
  border-radius: 12px;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  scroll-snap-align: start;
  padding: 1rem;

  &:hover {
    background: #eee;
  }
`;
